import { NgPlural, NgPluralCase } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBed,
  faHotTub,
  faMinus,
  faParking,
  faPlus,
  faSpoon,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { SharedData, UtilsService } from '@geekeno/ngx-website';
import * as AOS from 'aos';
import { add, differenceInCalendarDays, formatISO } from 'date-fns';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    FontAwesomeModule,
    MatButton,
    MatAnchor,
    RouterLink,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatInput,
    MatIconButton,
    NgPlural,
    NgPluralCase,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  icons = {
    wifi: faWifi,
    parking: faParking,
    bed: faBed,
    restaurant: faSpoon,
    jacuzzi: faHotTub,
    plus: faPlus,
    minus: faMinus,
  };
  bookingForm: FormGroup;
  today = new Date();
  tomorrow = add(this.today, { days: 1 });
  onFormSubmit() {
    if (this.bookingForm.invalid) {
      return false;
    }
    const values = this.bookingForm.value;
    const checkin = formatISO(values['checkin'], { representation: 'date' });
    const checkout = formatISO(values['checkout'], { representation: 'date' });
    const numberOfAdults = values['group_adults'];
    const numberOfChildren = values['group_children'];
    const numberOfRooms = this.numberOfRooms;
    const url = `https://www.booking.com/hotel/in/the-shore-agonda.en-gb.html?checkin=${checkin}&checkout=${checkout}&do_availability_check=1&group_adults=${numberOfAdults}&group_children=${numberOfChildren}&no_rooms=${numberOfRooms}`;
    window.open(url, '_blank');
    return false;
  }
  get numberOfDays(): number {
    const dates = this.bookingForm.value;
    if (dates['checkin'] && dates['checkout']) {
      return differenceInCalendarDays(dates['checkout'], dates['checkin']);
    }
    return 0;
  }
  get numberOfRooms(): number {
    return Math.ceil(this.bookingForm.value['group_adults'] / 2);
  }
  constructor(
    sharedData: SharedData,
    private utilsService: UtilsService,
  ) {
    sharedData.updateSiteTitle('Paradise has a new name | The Shore - Agonda');
    sharedData.updateSiteDescription(
      'Experience the beauty and tranquility of Agonda Beach at The Shore, a boho-chic resort with cozy cottages, a garden, a jacuzzi, and a restaurant. Enjoy the stunning views of the Arabian Sea, and the vibrant nightlife of South Goa. Book your stay today and get ready to relax, explore, and have fun at The Shore, Agonda.',
    );

    this.bookingForm = new FormGroup({
      checkin: new FormControl(this.tomorrow),
      checkout: new FormControl(add(this.today, { days: 3 })),
      group_adults: new FormControl(2),
      group_children: new FormControl(0),
    });
  }
  ngOnInit() {
    if (this.utilsService.isPlatformBrowser) {
      AOS.init({ once: true });
    }
  }

  incrementValue(fieldName: string, maxValue: number) {
    const value = Math.min(this.bookingForm.value[fieldName] + 1, maxValue);
    this.bookingForm.patchValue({ [fieldName]: value });
    return false;
  }
  decrementValue(fieldName: string, minValue: number) {
    const value = Math.max(this.bookingForm.value[fieldName] - 1, minValue);
    this.bookingForm.patchValue({ [fieldName]: value });
    return false;
  }
}
