<section class="background-primary flex-column">
  <div class="section-content">
    <h1 class="text-center">
      Get a glimpse of one of the best resorts in Agonda!
    </h1>
    <div class="split-content">
      <div>
        <h2>Key Highlights</h2>
        <div class="flex flex-column" style="justify-content: space-between">
          <h3>Spectacular Seascapes:</h3>
          <p>
            Immerse yourself in the stunning beauty of our resort nestled along
            the pristine shores of Agonda Beach in South Goa. Our gallery offers
            a captivating glimpse into the world of luxury, relaxation, and
            natural splendor that awaits you. Explore a curated collection of
            high-resolution images showcasing our exquisite accommodations, from
            elegant beachfront villas to cozy cottages which makes us one of the
            best resorts in Agonda.
          </p>
          <h3>Luxurious Accommodations:</h3>
          <p>
            Picture yourself unwinding on private balconies, savoring
            breathtaking ocean views of the picturesque shore of Agonda Beach,
            and indulging in modern comforts designed to exceed your
            expectations. Whether you're seeking a romantic retreat or a family
            getaway, our cozy accommodations cater to every preference and
            promise a blissful stay.Lose yourself in the mesmerizing sunsets,
            feel the soft sand beneath your feet, and envision the ultimate
            escape at The Shore Agonda.
          </p>
          <h3>Culinary Delights:</h3>
          <p>
            Prepare to tantalize your taste buds with a visual feast of culinary
            delights. From fresh seafood caught daily to flavorful Indian
            specialties and international favorites, our gallery highlights the
            diverse gastronomic experiences available at The Shore Agonda.
            Browse through images of our oceanfront restaurant, beachside bar,
            and cozy café, and let your senses come alive with anticipation.
          </p>
        </div>
      </div>
      <div>
        <img
          src="/assets/images/shore-entrance.webp"
          alt="Enter to The Shore at Agonda Beach Goa"
        />
      </div>
    </div>
    <div class="photo-gallery">
      @for (pic of photoGallery; track pic.url) {
        <div class="gallery-item">
          <img
            [ngSrc]="pic.thumbnailUrl"
            (click)="viewImage($index)"
            [alt]="pic.altText"
            loading="lazy"
            height="200"
            width="270"
          />
        </div>
      }
    </div>
  </div>
</section>
