import { Component } from '@angular/core';
import { SharedData } from '@geekeno/ngx-website';

@Component({
  selector: 'app-policies',
  standalone: true,
  imports: [],
  templateUrl: './policies.component.html',
  styleUrl: './policies.component.scss',
})
export class PoliciesComponent {
  constructor(sharedData: SharedData) {
    sharedData.updateSiteTitle('Applicable Policies at The Shore Agonda');
    sharedData.updateSiteDescription(
      "Discover the policies page of one of the best resorts in Agonda 'The Shore Agonda', outlining our commitment to ensuring a seamless and enjoyable experience for all guests. From reservation terms to cancellation policies, find all the information you need to plan your stay with confidence.",
    );
  }
}
