<section>
  <div class="section-content">
    <h1>Policies</h1>

    <p>
      The guest must add all people (adults or children) at the time of booking.
      If the booking system does not allow you to input the number of adults or
      children, you must assume that extra guests are not allowed. You are
      welcome to contact us to discuss this before making a booking.
    </p>

    <p>
      We reserve the right to refuse entry to the cottages if the size of the
      group or the guests’ ages differ from what you stated when making the
      reservation.
    </p>
  </div>
</section>

<section>
  <div class="section-content">
    <h2>Extra Children Policy</h2>
    <p>
      We consider children to be between 3-12 years old, and if you have two or
      more children above this age, we recommend that you reserve another
      cottage, and we will try and keep both cottages next to each other.
    </p>

    <p>
      Children below two years of age and below are free if they share their
      parent’s bed and only if that cottage is suitable for children. If the
      cottage does not state that it allows children, then you must assume it
      does not and contact us before booking.
    </p>

    <p>
      For each child, there is a nightly charge (this will be applied
      automatically when you reserve and inform us about your children), and it
      is assumed that children may share their parent’s bed. We can provide only
      one extra bed in the cottage as there is limited space. The extra bed is
      free.
    </p>

    <p>
      If you have more than one child, please let us know, and we will make the
      reservation. The extra person charge will apply, but we can only provide
      one extra bed.
    </p>

    <p>
      Some cottages are not suitable for extra people (above two people). When
      reserving, you will see the option for extra people against the cottage
      category. It is only sometimes possible or allowed to add extra people
      after making the reservation, so kindly add any additional people when
      making the reservation.
    </p>
  </div>
</section>
<section>
  <div class="section-content">
    <h2>Extra Adult Policy</h2>
    <p>
      We prefer for there to be only two adults in each cottage as it helps to
      prevent an overcrowded resort and maintain a peaceful environment. (guests
      aged 13 years or above are considered adults).
    </p>
    <p>
      It might not be possible to accommodate extra people in some cottages. The
      guest must add all people (adults or children) at the time of booking. If
      the booking system does not allow you to input the number of adults or
      children, you must assume that extra guests are not allowed. You are
      welcome to contact us to discuss this before making a booking.
    </p>
    <p>
      We reserve the right to refuse entry to the cottages if the size of the
      group or the guests’ ages differ from what you stated when making the
      reservation.
    </p>
  </div>
</section>
<section>
  <div class="section-content">
    <h2>Parking Policy</h2>
    <p>
      We do have parking facilities at all of our resorts. There is a private
      parking on site of The Shore Agonda where you can park a car. We are not
      responsible for cars or any belongings left in them. However, we generally
      have a security guard near the entrance and have had no issues to date.
    </p>
  </div>
</section>
