<section class="background-cover rooms-background">
  <div class="section-content">
    <div class="text-center box">
      <h1>Our Rooms</h1>
    </div>
    <div class="text-center" style="margin-top: 20px">
      <a
        mat-raised-button
        href="https://live.ipms247.com/booking/book-rooms-theshoreagonda"
        class="book-now-button"
        target="_blank"
        >Book Now</a
      >
    </div>
  </div>
</section>

@for (room of rooms; track room.title) {
  <section>
    <div class="section-content">
      <h2>{{ room.title }}</h2>
      <img [src]="room.src" [alt]="room.title" />
      <p>{{ room.description }}</p>
      <mat-accordion togglePosition="before" displayMode="flat">
        <mat-expansion-panel>
          <mat-expansion-panel-header>Amenities</mat-expansion-panel-header>

          <ul class="amenities-list">
            @if (room.hasJacuzzi) {
              <li>
                <span
                  ><fa-icon class="fa-2x" [icon]="icons.jacuzzi"></fa-icon>
                  Jacuzzi</span
                >
              </li>
            }
            <li>
              <span
                ><fa-icon class="fa-2x" [icon]="icons.fridge"></fa-icon>Mini
                Bar</span
              >
            </li>
            <li>
              <span
                ><fa-icon class="fa-2x" [icon]="icons.bed"></fa-icon>King size
                Bed</span
              >
            </li>
            <li>
              <span
                ><fa-icon class="fa-2x" [icon]="icons.bathRobe"></fa-icon
                >Bathrobes</span
              >
            </li>
            <li>
              <span
                ><fa-icon class="fa-2x" [icon]="icons.sofa"></fa-icon>Sofa</span
              >
            </li>
            <li>
              <span
                ><fa-icon class="fa-2x" [icon]="icons.table"></fa-icon>Writing
                Table</span
              >
            </li>
            <li>
              <span
                ><fa-icon class="fa-2x" [icon]="icons.toiletries"></fa-icon
                >Toiletries</span
              >
            </li>
            <li>
              <span
                ><fa-icon class="fa-2x" [icon]="icons.kettle"></fa-icon
                >Kettle</span
              >
            </li>
            <li>
              <span
                ><fa-icon class="fa-2x" [icon]="icons.hairDryer"></fa-icon>Hair
                Dryer</span
              >
            </li>
            <li>
              <span
                ><fa-icon class="fa-2x" [icon]="icons.wifi"></fa-icon>Free
                WiFi</span
              >
            </li>
            <li>
              <span
                ><fa-icon class="fa-2x" [icon]="icons.ac"></fa-icon>Individually
                Controlled AC</span
              >
            </li>
          </ul>
          <p>
            For details or enquiries about other amenities, please get in touch
            with us.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>Room Size</mat-expansion-panel-header>
          <p>480 square feet. Suggested for 2 adults.</p>

          <p>
            <a routerLink="/policies"
              ><strong
                >Please click here to check our policies for more
                details.</strong
              ></a
            >
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>
}
