<div class="footer-parent section-content">
  <div class="footer-base">
    <div class="footer-main">
      <div class="footer-inner">
        <div class="text-align-end p-1">
          <div class="mb-1">
            <strong class="text-uppercase">about The Shore Agonda</strong>
            <p>
              At The Shore Agonda, we believe in creating unforgettable
              experiences that linger in your heart long after you've bid
              farewell. Our resort is a harmonious blend of luxurious comforts,
              warm hospitality, and breathtaking natural beauty. Whether you
              seek solace in the tranquility of your private abode, indulge in
              delectable culinary creations, or embark on exhilarating
              adventures, we promise a stay that surpasses your every
              expectation.
            </p>
            <p>
              Come, immerse yourself in the timeless charm of one of the best
              resorts in South Goa The Shore Agonda, where every moment is a
              celebration of life, love, and laughter. Experience the magic of
              The Shore Agonda for yourself and discover why we're more than
              just a resort – we're a destination where dreams come true and
              memories are made to last a lifetime. Welcome to paradise. Welcome
              to The Shore Agonda.
            </p>
          </div>
          <div class="payment-methods-parent">
            <strong class="text-uppercase">we accept</strong>
            <div class="payment-methods">
              <img
                src="/assets/icons/visa.svg"
                alt="Visa"
                loading="lazy"
                title="Visa"
                width="1000"
                height="324"
              />
              <img
                src="/assets/icons/mastercard.svg"
                alt="MasterCard"
                loading="lazy"
                title="MasterCard"
                width="999"
                height="776"
              />
              <img
                src="/assets/icons/rupay.svg"
                alt="RuPay"
                loading="lazy"
                title="RuPay"
                width="300"
                height="232"
              />
              <img
                src="/assets/icons/upi.svg"
                alt="UPI"
                loading="lazy"
                title="UPI"
                width="300"
                height="106"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="footer-inner">
        <div class="contact-info">
          <strong class="text-uppercase">contact info</strong>
          <ul>
            <li>
              <span
                ><a
                  target="_blank"
                  href="https://www.google.com/maps?ll=15.009235,74.024825&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=13959655422541122835"
                  ><fa-icon [icon]="icons.address"></fa-icon>H. No 334,
                  Divanbag, Agonda, Goa</a
                ></span
              >
            </li>
            <li>
              <span>
                <a href="tel:+919997969977"
                  ><fa-icon [icon]="icons.phone"></fa-icon>+91 9997969977</a
                ></span
              >
              <span></span>
            </li>
            <li>
              <span
                ><a href="mailto:theshoreagonda@gmail.com"
                  ><fa-icon [icon]="icons.email"></fa-icon
                  >theshoreagonda&commat;gmail.com</a
                ></span
              >
            </li>
            <li>
              <span
                ><a [href]="whatsAppUrl"
                  ><fa-icon [icon]="icons.whatsapp"></fa-icon>+91 9997969977</a
                ></span
              >
            </li>
          </ul>
        </div>
        <div class="social-media">
          <strong class="text-uppercase">connect with us</strong>
          <ul>
            <li>
              <span
                ><a
                  href="https://www.facebook.com/profile.php?id=61555876336169"
                  target="_blank"
                  ><fa-icon [icon]="icons.facebook"></fa-icon>theshoreagonda</a
                ></span
              >
            </li>
            <li>
              <span
                ><a
                  href="https://www.instagram.com/theshoreagonda"
                  target="_blank"
                  ><fa-icon [icon]="icons.instagram"></fa-icon>theshoreagonda</a
                ></span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <small
      >Copyright © {{ currentYear }} <strong>The Shore Agonda</strong></small
    >
    <small
      >Made with <span class="text-red"> ♥</span> by
      <strong
        ><a href="https://www.geekeno.com" target="_blank">Geekeno</a></strong
      ></small
    >
  </div>
</div>
