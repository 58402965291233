import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SharedData, UtilsService } from '@geekeno/ngx-website';
import * as AOS from 'aos';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
})
export class AboutUsComponent implements OnInit {
  constructor(
    sharedData: SharedData,
    private utilsService: UtilsService,
  ) {
    sharedData.updateSiteTitle(
      'About Us: Unveiling The Story of The Shore Agonda',
    );
    sharedData.updateSiteDescription(
      ' The Shore Agonda is a 14-villa (4 Ground floor seafront, 6 first floor Seaview villa and 4 Garden view rooms) boutique hotel located along the serene and virgin Agonda Beach in south Goa.',
    );
  }
  ngOnInit() {
    if (this.utilsService.isPlatformBrowser) {
      AOS.init({ once: true });
    }
  }
}
