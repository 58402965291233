import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SharedData, UtilsService } from '@geekeno/ngx-website';
import { filter } from 'rxjs';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'shore-agonda';
  private previousUrlWithoutQueryParams = '';

  constructor(
    router: Router,
    private sharedData: SharedData,
    private utilsService: UtilsService,
  ) {
    this.sharedData.setBaseURL('https://theshoreagonda.com');
    this.sharedData.updateSiteImage('/assets/logo.svg');
    this.sharedData.removeArticleTags();

    router.events
      .pipe(filter((event2) => event2 instanceof NavigationEnd))
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.sharedData.updatePageUrl(router.url);
          const urlWithoutQueryParams = router.url.split('?')[0];
          if (this.previousUrlWithoutQueryParams != urlWithoutQueryParams) {
            this.sharedData.updateCanonicalUrl(urlWithoutQueryParams);
            if (
              !router.getCurrentNavigation()?.extractedUrl.fragment &&
              this.utilsService.isPlatformBrowser
            ) {
              window.scroll({ top: 0, left: 0 });
            }
          }
        },
      });
  }
}
