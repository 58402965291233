<section
  class="background-cover height-100vh bg-image-1 flex justify-center align-center"
>
  <div class="section-content">
    <h1 class="text-center box" data-aos="fade-up" data-aos-duration="2500">
      Drinks, Food and Everything Good
    </h1>
    <p class="box" data-aos="fade-up" data-aos-duration="2500">
      Indulge your senses at our resort's exceptional restaurant, where every
      meal is a celebration of flavors and a feast for the eyes. Our culinary
      team is dedicated to providing a remarkable dining experience, blending
      local influences with international flair. Here's what awaits you:
    </p>
  </div>
</section>
<section class="section background-primary flex-column">
  <div class="section-content" data-aos="fade-right" data-aos-duration="2500">
    <h2>Diverse Culinary Delights</h2>
    <p>
      Embark on a gastronomic journey with our diverse menu, expertly crafted to
      satisfy every palate. From local delicacies to international favorites,
      our chefs use the finest ingredients to create dishes that showcase the
      richness of flavors.
    </p>
  </div>
</section>
<section class="background-secondary flex-column">
  <div class="section-content" data-aos="fade-left" data-aos-duration="2500">
    <h2>Seaside Dining</h2>
    <p>
      Immerse yourself in the tranquil beauty of our surroundings as you dine
      with a backdrop of the serene sea. Our seaside tables provide the perfect
      setting for romantic dinners, family gatherings, or celebrations with
      friends.
    </p>
  </div>
</section>
<section>
  <img
    role="presentation"
    src="/assets/images/seaside-dining.webp"
    loading="lazy"
    alt="Sea Side Dining Restaurant"
  />
</section>

<section class="section background-primary flex-column">
  <div class="section-content" data-aos="fade-right" data-aos-duration="2500">
    <h2>Signature Cocktails & Beverages with Sunset Views</h2>
    <p>
      Sip on handcrafted cocktails while enjoying panoramic views of the sun
      setting over the horizon. Our mixologists have curated a menu of signature
      drinks, offering refreshing concoctions and premium spirits, each inspired
      by the vibrant colors of the tropics.
    </p>
  </div>
</section>
<section class="background-secondary">
  <div class="section-content" data-aos="fade-left" data-aos-duration="2500">
    <p>
      Join us at the heart of our resort, where each meal is a celebration of
      the senses. Indulge in the culinary treasures of our restaurant and let
      the flavors of paradise linger on your palate.
    </p>
  </div>
</section>
