import { Routes } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { HomeComponent } from './pages/home/home.component';
import { PoliciesComponent } from './pages/policies/policies.component';
import { RestaurantComponent } from './pages/restaurant/restaurant.component';
import { RoomsComponent } from './pages/rooms/rooms.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', redirectTo: 'about-us' },
  { path: 'about-us', component: AboutUsComponent },
  // { path: 'contact', redirectTo: 'contact-us' },
  // { path: 'contact-us', component: ContactUsComponent },
  { path: 'rooms', component: RoomsComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'restaurant', component: RestaurantComponent },
  { path: 'policies', component: PoliciesComponent },
];
