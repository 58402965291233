import { Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelopeOpen,
  faLocationDot,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [FaIconComponent, MatToolbarModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  currentYear = new Date().getFullYear(); // used for copyright

  get whatsAppUrl(): string {
    return encodeURI(
      `https://wa.me/919997969977?text=I wanted more details about your resort`,
    );
  }
  icons = {
    instagram: faInstagram,
    facebook: faFacebook,
    phone: faPhone,
    whatsapp: faWhatsapp,
    email: faEnvelopeOpen,
    address: faLocationDot,
  };
}
