import { Component, ViewChild } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faBars,
  faCalendarCheck,
  faEnvelopeOpen,
  faMap,
  faPhone,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from '../../models/menu-item';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule,
    MatToolbarModule,
    FaIconComponent,
    MatSidenavModule,
    MatListModule,
    MatAnchor,
    MatButton,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @ViewChild('snav') snav!: MatSidenav;

  menuItems: MenuItem[] = [
    { title: 'Home', url: '/', activeOptions: { exact: true } },
    { title: 'About Us', url: '/about-us', activeOptions: { exact: true } },
    { title: 'Rooms', url: '/rooms', activeOptions: { exact: true } },
    { title: 'Gallery', url: '/gallery', activeOptions: { exact: true } },
    { title: 'Restaurant', url: '/restaurant', activeOptions: { exact: true } },
    // { title: 'Contact Us', url: '/contact-us', activeOptions: {exact: true} },
  ];
  icons = {
    bars: faBars,
    cross: faTimes,
    phone: faPhone,
    email: faEnvelopeOpen,
    address: faMap,
    booking: faCalendarCheck,
  };

  onNavOpen() {
    document.documentElement.style.setProperty(`--header-height`, '357px');
  }
  onNavClose() {
    document.documentElement.style.setProperty(`--header-height`, '100px');
  }
}
