<section
  class="background-cover hero flex justify-center align-center flex-column"
>
  <div class="section-content box" data-aos="fade-up" data-aos-duration="2500">
    <h1>Welcome to The Shore - Agonda</h1>
    <p>
      Discover the epitome of luxury and relaxation at The Shore. Right along
      the shores of Agonda, our resort is a sanctuary where indulgence meets
      tranquility. Whether you seek a serene escape, exciting adventures, or a
      perfect blend of both, our resort is designed to exceed your expectations.
    </p>
  </div>
</section>

<section class="background-primary">
  <div class="section-content" data-aos="fade-right" data-aos-duration="2500">
    <h2>Our Story</h2>
    <p>
      The Shore Agonda is a 14-villa (4 Ground floor seafront, 6 first floor
      Seaview villa and 4 Garden view rooms) boutique hotel located along the
      serene and virgin Agonda Beach in south Goa. Away from the hustle and
      bustle, Agonda beach is one of the best ranked beaches of Asia by
      Travellers’ choice awards 2018. It is the perfect serene beach destination
      replete with the tropical sun and warm ocean waters. The eclectic blend of
      our white cottages with Thatched roof lined by colourful bougainvillea and
      ficus plants, the soothing yellow of the sand and the azure hues of the
      Arabian Sea offers you the perfect tropical vacation stay at the shore. We
      are a small property and so every guest can be assured of personal
      attention to all their needs. Connecting with our guests along with our
      energetic and ever enthusiastic team is our pride.
    </p>
    <p>
      The beachside restaurant and bar offer panoramic views of the sea with the
      golden sunsets being our guests’ favourite time of the day. All our
      services at The Shore are designed to make guests feel as much at home as
      possible while having a great time. The balmy ocean breeze is sure to keep
      you rejuvenated throughout the day whether you enjoy the sea views from 10
      direct sea view cottages or take a plunge into the warm sea. Our guests
      enjoy their time indulging in numerous activities. Whether its kayaking or
      renting a bike to explore the hinterland and the wildlife of Goa, you only
      need to visit the front desk and our team will help you out.
    </p>
    <p>
      For the best offers for your stay at The Shore resort, we request you to
      contact us directly via phone or WhatsApp or email. We hope to see you
      soon for the perfect Goan beach stay!
    </p>

    <p>
      <em>Dev Borem Korun (Thank you!)</em>
    </p>
  </div>
</section>
<section
  class="background-cover vision-section flex flex-column justify-center"
>
  <div class="overlay"></div>
  <div
    class="section-content text-white"
    data-aos="fade-left"
    data-aos-duration="2500"
  >
    <h2>Timings</h2>
    <div class="timings">
      <h3><strong>Check-In</strong> <span>02:00 PM</span></h3>
      <h3><strong>Check-Out</strong> <span>11:00 AM</span></h3>
    </div>
    <p>
      It might not be possible to host more than 2 adults in some cottages.
      <a routerLink="/policies"
        ><strong
          >Please click here to check our policies for more details.</strong
        ></a
      >
    </p>
  </div>
</section>
<section class="background-primary">
  <div class="section-content" data-aos="fade-right" data-aos-duration="2500">
    <h2>What Sets Us Apart</h2>
    <p>
      From meticulously designed accommodations to world-class dining, spa
      retreats, and exciting recreational activities, we take pride in offering
      a comprehensive array of amenities. Our dedicated team is committed to
      ensuring that every guest experiences the warmth of our hospitality and
      the enchantment of our surroundings.
    </p>
  </div>
</section>
<section class="background-secondary">
  <div class="section-content" data-aos="fade-left" data-aos-duration="2500">
    <h2>Explore The Shore</h2>
    <p>
      Join us on a journey of luxury and serenity. Whether you're planning a
      romantic getaway, a family vacation, or a corporate retreat, The Shore
      Agonda welcomes you to a world where every moment is crafted for your
      utmost enjoyment.
    </p>
  </div>
</section>

<section class="background-primary">
  <div class="section-content" data-aos="fade-right" data-aos-duration="2500">
    <h2>Sustainability Commitment</h2>
    <p>
      As stewards of the environment, we are dedicated to sustainability and
      responsible tourism. We actively implement eco-friendly practices to
      minimize our ecological footprint and contribute to the well-being of our
      surroundings.
    </p>
  </div>
</section>
<section class="background-secondary">
  <div class="section-content" data-aos="fade-left" data-aos-duration="2500">
    <p>
      Thank you for considering <strong>The Shore</strong>, Agonda as your
      destination. We look forward to welcoming you and creating cherished
      memories together.
    </p>
  </div>
</section>
