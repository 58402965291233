import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { SharedData } from '@geekeno/ngx-website';
import { IAlbum, Lightbox, LightboxModule } from 'ngx-lightbox';

interface Photo {
  url: string;
  thumbnailUrl: string;
  altText: string;
}

@Component({
  selector: 'app-gallery',
  standalone: true,
  imports: [LightboxModule, NgOptimizedImage],
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss',
})
export class GalleryComponent {
  photoGallery: Photo[] = [
    {
      url: '/assets/images/gallery/SLK55856.webp',
      thumbnailUrl: '/assets/images/gallery/SLK55856-thumb.webp',
      altText:
        'A lavish bedroom showcasing a sumptuous bed, exuding elegance and comfort, adorned with plush pillows and luxurious linens.',
    },
    {
      url: '/assets/images/gallery/SLK55865.webp',
      thumbnailUrl: '/assets/images/gallery/SLK55865-thumb.webp',
      altText:
        'A luxurious beach house with a charming thatched roof and pristine white walls, nestled in a serene coastal setting.',
    },
    {
      url: '/assets/images/gallery/SLK55898.webp',
      thumbnailUrl: '/assets/images/gallery/SLK55898-thumb.webp',
      altText:
        'A lavish balcony with a beachfront view, featuring a hot tub and comfortable chairs for ultimate relaxation.',
    },
    {
      url: '/assets/images/gallery/SLK55936.webp',
      thumbnailUrl: '/assets/images/gallery/SLK55936-thumb.webp',
      altText:
        'Luxurious bedroom with jacuzzi and wooden deck, perfect for relaxation and indulgence.',
    },
    {
      url: '/assets/images/gallery/SLK56005.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56005-thumb.webp',
      altText:
        'Bedroom with bed and ocean view, featuring calming blue hues and natural light pouring in through large windows.',
    },
    {
      url: '/assets/images/gallery/SLK56152.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56152-thumb.webp',
      altText:
        'A stunning beach house with a wooden porch, offering breathtaking views of the vast and serene ocean.',
    },
    {
      url: '/assets/images/gallery/SLK56015.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56015-thumb.webp',
      altText:
        'A lavish bedroom overlooking the serene beach and vast ocean, offering a breathtaking view of nature"s beauty.',
    },
    {
      url: '/assets/images/gallery/SLK56032.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56032-thumb.webp',
      altText:
        'A lavish, golden chandelier illuminating a grand ballroom with crystal-clear elegance and opulence.',
    },
    {
      url: '/assets/images/gallery/SLK56041.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56041-thumb.webp',
      altText:
        'A cozy bedroom with a king-sized bed and a breathtaking beach view. The perfect spot to relax and unwind.',
    },
    {
      url: '/assets/images/gallery/SLK56073.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56073-thumb.webp',
      altText:
        'A modern bathroom with a spacious mirror and a sleek sink, perfect for getting ready in style.',
    },
    {
      url: '/assets/images/gallery/SLK55875.webp',
      thumbnailUrl: '/assets/images/gallery/SLK55875-thumb.webp',
      altText:
        'A tropical beach house with a thatched roof and palm trees, offering a serene escape by the sea.',
    },
    {
      url: '/assets/images/gallery/SLK55995.webp',
      thumbnailUrl: '/assets/images/gallery/SLK55995-thumb.webp',
      altText: 'Relaxing bedroom with bed and ocean view.',
    },
    {
      url: '/assets/images/gallery/SLK56095.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56095-thumb.webp',
      altText: 'Luxurious bathroom with elegant sink and stylish mirror.',
    },
    {
      url: '/assets/images/gallery/SLK56116.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56116-thumb.webp',
      altText:
        'A modern bathroom with a sleek sink and a large mirror reflecting the clean and stylish design.',
    },
    {
      url: '/assets/images/gallery/SLK56142.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56142-thumb.webp',
      altText:
        'A serene beachfront scene showcasing a wooden deck adorned with elegant table and chairs, inviting relaxation and enjoyment.',
    },
    {
      url: '/assets/images/gallery/SLK56176.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56176-thumb.webp',
      altText:
        'A lavish, golden chandelier illuminating a grand ballroom with crystal-clear brilliance.',
    },
    {
      url: '/assets/images/gallery/SLK56184.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56184-thumb.webp',
      altText:
        'A cozy white bed in a room with a high ceiling, inviting you to relax and unwind.',
    },
    {
      url: '/assets/images/gallery/SLK56188.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56188-thumb.webp',
      altText:
        'A serene ocean view bedroom with a cozy bed, offering a tranquil escape and a breathtaking sight.',
    },
    {
      url: '/assets/images/gallery/SLK56193.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56193-thumb.webp',
      altText:
        'A cozy balcony with a wooden railing and a single chair, perfect for enjoying a peaceful moment outdoors.',
    },
    {
      url: '/assets/images/gallery/SLK56199.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56199-thumb.webp',
      altText:
        'A wooden balcony railing overlooking the beach with a beautiful view of the ocean.',
    },
    {
      url: '/assets/images/gallery/SLK56223.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56223-thumb.webp',
      altText:
        'A beachfront balcony with cozy chairs and a thatched roof, offering a perfect spot to relax and enjoy the view.',
    },
    {
      url: '/assets/images/gallery/SLK56364.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56364-thumb.webp',
      altText:
        'Peaceful bedroom with stunning ocean vista, great for calming vibes.',
    },
    {
      url: '/assets/images/gallery/SLK55928.webp',
      thumbnailUrl: '/assets/images/gallery/SLK55928-thumb.webp',
      altText:
        'A cozy bedroom with a luxurious jacuzzi and a beautiful wooden deck, perfect for relaxation and unwinding.',
    },
    {
      url: '/assets/images/gallery/SLK56318.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56318-thumb.webp',
      altText:
        'A modern bathroom with a sleek sink and a large mirror reflecting the clean and stylish design.',
    },
    {
      url: '/assets/images/gallery/SLK56225.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56225-thumb.webp',
      altText:
        'A cozy beach house with a wooden porch overlooking the vast ocean.',
    },
    {
      url: '/assets/images/gallery/SLK56252.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56252-thumb.webp',
      altText:
        'A cozy beach house with a wooden porch, offering breathtaking ocean views. The perfect seaside retreat!',
    },
    {
      url: '/assets/images/gallery/SLK56296.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56296-thumb.webp',
      altText:
        'Interior of a bedroom with a bed and a couch, creating a comfortable space.',
    },
    {
      url: '/assets/images/gallery/SLK56301.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56301-thumb.webp',
      altText:
        'Relaxing bedroom with stunning ocean and beach view, perfect for unwinding.',
    },
    {
      url: '/assets/images/gallery/SLK56209.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56209-thumb.webp',
      altText: 'Balcony with chairs and thatched roof overlooking beach.',
    },
    {
      url: '/assets/images/gallery/SLK56326.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56326-thumb.webp',
      altText:
        'Bathroom interior featuring a sink, mirror, and toilet in a clean design.',
    },
    {
      url: '/assets/images/gallery/SLK56354.webp',
      thumbnailUrl: '/assets/images/gallery/SLK56354-thumb.webp',
      altText:
        'Bedroom with ocean view, featuring a cozy bed, large windows, and calming blue decor.',
    },
  ];
  private readonly album: Array<IAlbum> = [];

  constructor(
    private lightbox: Lightbox,
    sharedData: SharedData,
  ) {
    this.album = this.photoGallery.map((pic) => ({
      src: pic.url,
      thumb: pic.thumbnailUrl,
      caption: pic.altText,
    }));

    sharedData.updateSiteTitle('Discover The Shore Agonda Through Our Gallery');
    sharedData.updateSiteDescription(
      'Explore visual tapestry of our coastal haven. Dive into captivating images capturing the essence of serenity and luxury that await you.',
    );
  }

  viewImage(index: number) {
    this.lightbox.open(this.album, index, {
      disableScrolling: true,
      centerVertically: true,
      fadeDuration: 0.2,
      resizeDuration: 0.5,
    });
  }
}
