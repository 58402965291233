import { Component, OnInit } from '@angular/core';
import { SharedData, UtilsService } from '@geekeno/ngx-website';
import * as AOS from 'aos';

@Component({
  selector: 'app-restaurant',
  standalone: true,
  imports: [],
  templateUrl: './restaurant.component.html',
  styleUrl: './restaurant.component.scss',
})
export class RestaurantComponent implements OnInit {
  constructor(
    sharedData: SharedData,
    private utilsService: UtilsService,
  ) {
    sharedData.updateSiteTitle(
      'Premier Restaurant: Drinks, Food, & More | The Shore Agonda',
    );
    sharedData.updateSiteDescription(
      "Indulge your senses at our resort's exceptional restaurant, where every meal is a celebration of flavors and a feast for the eyes. Our culinary team is dedicated to providing a remarkable dining experience, blending local influences with international flair.",
    );
  }
  ngOnInit() {
    if (this.utilsService.isPlatformBrowser) {
      AOS.init({ once: true });
    }
  }
}
