<mat-toolbar>
  <mat-toolbar-row class="mini-info-row show-large-devices">
    <span>The Shore, Agonda, Goa</span>
    <span class="spacer"></span>
    <ul>
      <li>
        <span>
          <a
            href="https://live.ipms247.com/booking/book-rooms-theshoreagonda"
            target="_blank"
          >
            <fa-icon [icon]="icons.booking"></fa-icon>Book Now</a
          >
        </span>
      </li>
      <li>
        <span
          ><a href="mailto:theshoreagonda@gmail.com" target="_blank"
            ><fa-icon [icon]="icons.email"></fa-icon>Contact Us</a
          ></span
        >
      </li>
      <li>
        <span
          ><a
            target="_blank"
            href="https://www.google.com/maps/place/2XVP%2BGJ9+THE+SHORE+AGONDA,+Agonda+Beach+Rd,+Agonda,+Goa+403702/@15.0434753,73.9870352,17z/data=!4m6!3m5!1s0x3bbe4f0029405ebb:0x169589e98fc281ad!8m2!3d15.0434753!4d73.9870352!16s%2Fg%2F11y2w5_7gz?hl=en&gl=IN"
            ><fa-icon [icon]="icons.address"></fa-icon>How to Find Us</a
          ></span
        >
      </li>
      <li>
        <span>
          <a href="tel:+919649649997" target="_blank"
            ><fa-icon [icon]="icons.phone"></fa-icon>+91 9649649997</a
          ></span
        >
        <span></span>
      </li>
    </ul>
  </mat-toolbar-row>
  <mat-toolbar-row class="navbar">
    <div class="section-content">
      <a href="/"
        ><img
          src="/assets/logo.svg"
          class="logo"
          alt="logo"
          title="The Shore Agonda"
          height="48"
          width="64"
          loading="eager"
        />
      </a>
      <span class="spacer"></span>
      <div class="show-large-devices">
        @for (menuItem of menuItems; track menuItem.url) {
          <a
            mat-button
            [routerLink]="menuItem.url"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="menuItem.activeOptions"
            >{{ menuItem.title }}</a
          >
        }
      </div>
      <button
        mat-button
        title="Navigation Menu"
        (click)="snav.toggle()"
        class="show-small-devices"
      >
        <fa-icon [icon]="icons.bars" class="fa-2x"></fa-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container
  (backdropClick)="snav.close()"
  class="show-small-devices"
>
  <mat-sidenav
    position="end"
    #snav
    (openedStart)="onNavOpen()"
    (closedStart)="onNavClose()"
    (keydown.escape)="snav.close()"
    mode="side"
    [fixedInViewport]="true"
    fixedTopGap="100"
  >
    <mat-nav-list class="mobile-navbar">
      @for (menuItem of menuItems; track menuItem.url) {
        <a
          mat-list-item
          [routerLink]="menuItem.url"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="menuItem.activeOptions"
          (click)="snav.close()"
          >{{ menuItem.title }}</a
        >
      }
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
