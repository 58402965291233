<section
  class="logo-parent"
  style="position: relative"
  xmlns="http://www.w3.org/1999/html"
>
  <h1
    class="logo text-white text-center"
    data-aos="fade-up"
    data-aos-easing="linear"
    data-aos-duration="2500"
    data-aos-anchor-placement="center-bottom"
  >
    The Shore<br />Agonda
  </h1>
  <p
    class="logo text-white text-center"
    data-aos="fade-up"
    data-aos-easing="linear"
    data-aos-duration="2500"
    data-aos-anchor-placement="center-bottom"
  >
    paradise has a new name
  </p>
  <div
    class="text-center"
    data-aos="fade-up"
    data-aos-easing="linear"
    data-aos-duration="2500"
    data-aos-anchor-placement="center-bottom"
  >
    <a
      mat-raised-button
      href="https://live.ipms247.com/booking/book-rooms-theshoreagonda"
      class="book-now-button"
      target="_blank"
      >Book Now</a
    >
  </div>
</section>
<section class="background-secondary">
  <div class="section-content">
    <p>
      The Shore Agonda is a 14-villa (4 Ground floor seafront, 6 first floor
      Seaview villa and 4 Garden view rooms) boutique hotel located along the
      serene and virgin Agonda Beach in south Goa. Away from the hustle and
      bustle, Agonda beach is one of the best ranked beaches of Asia by
      Travellers’ choice awards 2018.
    </p>
    <p>
      It is the perfect serene beach destination replete with the tropical sun
      and warm ocean waters.
    </p>
    <p class="text-right">
      <small><a routerLink="/about-us">Read More About Us</a></small>
    </p>
  </div>
</section>
<section
  class="background-cover sea-from-room flex justify-center align-center"
>
  <h2 class="section-content box">
    Wake up in luxury to the view of The Shore
  </h2>
</section>

<section class="background-primary">
  <div class="section-content">
    <h2>Amenities & Facilities</h2>
    <p>
      At The Shore Agonda, we are dedicated to providing an unparalleled
      experience, ensuring every guest enjoys a perfect blend of relaxation,
      recreation, and indulgence. Come, discover a world of luxury and create
      lasting memories with us.
    </p>

    <div class="amenities-list">
      <ul>
        <li>
          <span
            ><p class="text-uppercase">
              <fa-icon [icon]="icons.wifi" class="fa-2x"></fa-icon> free wifi
            </p></span
          >
        </li>
        <li>
          <span
            ><p class="text-uppercase">
              <fa-icon [icon]="icons.bed" class="fa-2x"></fa-icon> luxurious
              rooms and suites
            </p></span
          >
        </li>
        <li>
          <span
            ><p class="text-uppercase">
              <fa-icon [icon]="icons.restaurant" class="fa-2x"></fa-icon>
              restaurant & bar
            </p></span
          >
        </li>
        <li>
          <span
            ><p class="text-uppercase">
              <fa-icon [icon]="icons.jacuzzi" class="fa-2x"></fa-icon> jacuzzi
            </p></span
          >
        </li>
      </ul>
      <p class="text-right">
        <small
          ><strong><a routerLink="/rooms">View Details</a></strong>
        </small>
      </p>
    </div>
  </div>
</section>

<section class="map-section">
  <iframe
    title="The Shore Agonda location on Google Maps"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3853.0838940359245!2d73.9844602777543!3d15.043475285497122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbe4f0029405ebb%3A0x169589e98fc281ad!2sTHE%20SHORE%20AGONDA!5e0!3m2!1sen!2sin!4v1707202387394!5m2!1sen!2sin"
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
  ></iframe>
</section>

<section class="background-secondary">
  <h3 class="section-content">
    Indulge in the epitome of comfort and leisure, where every stay is a tale of
    relaxation and refinement at
    <strong>&ldquo;The Shore&rdquo;</strong>
    &nbsp;<em>Agonda, Goa</em>
  </h3>
</section>
